<template>
  <div class="accBinding">
    <!-- accBinding 账号绑定 -->
    <div class="nav">
      <div class="nav_title">账号绑定</div>
      <div class="nav_left_btn" @click="toPage('Center')">
        <img :src="cancel" alt width="15" height="25" />
        <!-- <span class="back">返回</span> -->
      </div>
    </div>
    <div class="accBinding-main">
      <van-form ref="form">
        <div style="margin-bottom: 1rem">绑定新号码</div>
        <div class="tel-form">
          <div class="area-num">+86</div>
          <van-field
            v-model="mobile"
            class="input"
            maxlength="11"
            placeholder="请输入手机号码"
            :rules="telRules"
            name="mobile"
          />
        </div>
        <!-- </van-form> -->
        <div style="margin-bottom: 1rem">短信验证码</div>
        <div class="code-form" style="display: flex;flex-direction: row;">
          <van-field
            v-model="code"
            class="input"
            name="code"
            placeholder="请输入验证码"
            :rules="codeRules"
          />
          <div v-if="show == 0" class="getCodeBtn" @click="getCode">发送验证码</div>
          <div v-if="show == 1" class="getCodeBtn">已发送{{ count }}秒</div>
          <div v-if="show == 2" class="getCodeBtn" @click="getCode">重新获取</div>
        </div>
      </van-form>
      <!-- <div class="code-form">
        <van-field
          v-model="code"
          class="input"
          maxlength="6"
          placeholder="请输入短信验证码"
          :rules="codeRules"
        />
        <van-button v-if="show == 0" class="getCodeBtn" @click="getCode">
          获取验证码
        </van-button>
        <van-button v-if="show == 1" class="getCodeBtn">
          已发送{{ count }}秒
        </van-button>
        <van-button v-if="show == 2" class="getCodeBtn" @click="getCode">
          重新获取
        </van-button>
      </div>-->
      <van-button type="primary" class="submit" @click="submit">提交</van-button>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import { getVerifyCode, getRebindPhone, getAccInfo } from "@/api/person";
import { Toast } from "vant";

export default {
  data() {
    return {
      cancel,
      mobile: "", // 手机号码
      code: "", // 验证码
      phone: "",
      custID: "",
      // 校验手机号码
      telRules: [
        {
          required: true,
          // message: '手机号码不能为空',
          trigger: "onBlur",
        },
        {
          // 自定义校验规则
          validator: (value) => {
            return /^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/.test(
              value
            );
          },
          // message: '请输入正确格式的手机号码',
          trigger: "onBlur",
        },
      ],
      codeRules: [
        {
          required: true,
          // message: '验证码不能为空',
          trigger: "onBlur",
        },
      ],
      userId: null,
      show: 0,
      count: "",
      timer: null,
      sTimer: null,
      sCount: "",
    };
  },
  created() {
    // const { token } = this.$store.getters
    // console.log('账号绑定', window.location.href)
    // // const { queryEmployee, fetchBanners, getOrg, afterLogin } = this
    // const { phone, custID } = this.$route.query
    // if (!token) {
    //   if (phone) {
    //     if (phone.indexOf(',') > -1) {
    //       const phones = phone.split(',')
    //       this.phoneShow = true
    //       this.phones = phones.map((value) => {
    //         return { name: value }
    //       })
    //     } else {
    //       this.phone = phone
    //       // getOrg({ phone })
    //     }
    //   } else if (custID) {
    //     sessionStorage.setItem('custID', JSON.stringify(this.$route.query.custID))
    //     // this.$router.push({ name: 'Login', query: { custID }})
    //   } else {
    //     // afterLogin(false)
    //   }
    // } else {
    //   // queryEmployee()
    //   // fetchBanners()
    // }
    this.getInfoList();
    // const { custID } = this.$route.query
    // this.custID = custID
    this.custID = sessionStorage.getItem("custID");
  },
  methods: {
    toPage(name) {
      // console.log(name)
      this.$router.push({ name });
    },
    toHome() {
      this.$router.back();
    },
    toBack() {
      this.$router.back();
    },
    // 获取用户信息
    async getInfoList() {
      const res = await getAccInfo();
      // console.log(res.data)
      this.userId = res.data.id;
    },

    // 点击获取验证码
    getCode() {
      // console.log('获取验证码', this.mobile)
      this.$refs.form
        .validate("mobile")
        .then(async () => {
          const res = await getVerifyCode({
            phone: this.mobile,
            appKey: "ZHONGHEZHIHUI123!@#",
          });
          console.log("验证码", res.data);
          Toast.success("已发送验证码");
          const times = 60;
          if (!this.timer) {
            this.count = times;
            this.show = 1;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= times) {
                this.count--;
              } else {
                this.show = 2;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        })
        .catch(() => {
          // console.log('error')
          Toast.success("请输入正确的手机号");
        });
    },

    // // 点击获取验证码
    // async getCode() {
    //   // console.log('获取验证码', this.mobile)
    //   const res = await getVerifyCode({
    //     phone: this.mobile,
    //     appKey: 'ZHONGHEZHIHUI123!@#'
    //   })
    //   console.log('验证码', res.data)
    //   Toast.success('已发送验证码')
    //   const times = 60
    //   if (!this.timer) {
    //     this.count = times
    //     this.show = 1
    //     this.timer = setInterval(() => {
    //       if (this.count > 0 && this.count <= times) {
    //         this.count--
    //       } else {
    //         this.show = 2
    //         clearInterval(this.timer)
    //         this.timer = null
    //       }
    //     }, 1000)
    //   }
    // },
    // // 点击提交
    // async submit() {
    //   console.log('提交')
    //   this.custID = JSON.parse(sessionStorage.getItem('custID'))
    //   const res = await getRebindPhone({
    //     'userId': this.userId,
    //     'custID': '200132939645053668a2e8a9ee60d44d4c83ae142ea7207d72',
    //     'phone': this.mobile,
    //     'code': this.code
    //   })
    //   console.log(res)

    //   if (res.code === 0) {
    //     Toast.success('绑定成功')
    //     // 延迟两秒跳转地址
    //     const times = 2
    //     if (!this.sTimer) {
    //       this.sCount = times
    //       this.sTimer = setInterval(() => {
    //         if (this.sCount > 0 && this.sCount <= times) {
    //           this.sCount--
    //         } else {
    //           this.$router.push({ name: 'Home' })
    //           clearInterval(this.sTimer)
    //           this.sTimer = null
    //         }
    //       }, 1000)
    //     }
    //   // this.$router.push({ name: 'Home' })
    //   }
    // }

    submit() {
      // this.custID = JSON.parse(sessionStorage.getItem('custID'))
      this.$refs.form
        .validate("mobile")
        .then(() => {
          this.$refs.form
            .validate("code")
            .then(async () => {
              const res = await getRebindPhone({
                userId: this.userId,
                // 'custID': '200132939645053668a2e8a9ee60d44d4c83ae142ea7207d72',
                custID: this.custID,
                phone: this.mobile,
                code: this.code,
              });
              // console.log(res)
              if (res.code === 0) {
                Toast.success("绑定成功");
                // 延迟两秒跳转地址
                const times = 2;
                if (!this.sTimer) {
                  this.sCount = times;
                  this.sTimer = setInterval(() => {
                    if (this.sCount > 0 && this.sCount <= times) {
                      this.sCount--;
                    } else {
                      sessionStorage.removeItem("personInfo");
                      this.$router.push({ name: "Home" });
                      clearInterval(this.sTimer);
                      this.sTimer = null;
                    }
                  }, 1000);
                }
                // this.$router.push({ name: 'Home' })
              }
            })
            .catch(() => {
              // console.log('error')
              Toast.success("验证码不能为空");
            });
        })
        .catch(() => {
          Toast.success("请输入正确的手机号");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-field__body {
  display: block;
  box-sizing: border-box;
  padding: 0 10px;
  height: 2.5rem;
  line-height: 2.5rem;
  border: 1px solid rgba(181, 201, 210, 0.6);
  // background: #fff !important;
}

.accBinding {
  // background: #f1f1f1;
  height: 100%;
  display: flex;
  flex-direction: column;
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .accBinding-main {
    flex: 1;
    box-sizing: border-box;
    padding: 4rem 1.2rem 0 1.2rem;
    background: #fff;
    .tel-form {
      display: flex;
      position: relative;
      margin-bottom: 0.5rem;
      .area-num {
        width: 12%;
        height: 2.5rem;
        line-height: 2.5rem;
        border: 1px solid rgba(181, 201, 210, 0.6);
        box-sizing: border-box;
        text-align: center;
        position: absolute;
        top: 10px;
        left: 0px;
      }
      .input {
        width: 88%;
        margin: 0 0 0 12%;
        padding: 10px 0;
        background: #fff;
      }
    }
    .code-form {
      display: flex;
      position: relative;
      .input {
        width: 60%;
        padding: 10px 0;
      }
      .getCodeBtn {
        position: absolute;
        top: 10px;
        right: 0px;
        box-sizing: border-box;
        padding: 0 1rem;
        font-size: 0.8rem;
        border-radius: 8px;
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        color: #fff;
        border: 0;
        background: #db2020;
        width: 35%;
      }
    }
    .input {
      box-sizing: border-box;
      height: 5rem;
      margin-bottom: 1rem;
      // border: 1px solid #d2d2d2;
      .getCodeBtn {
        position: absolute;
        right: 0;
        top: 0;
        box-sizing: border-box;
        padding: 0 1rem;
        font-size: 0.8rem;
        border-radius: 8px;
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        color: #fff;
        border: 0;
        background: #db2020;
        width: 35%;
      }
    }
    .submit {
      color: #fff;
      height: 2.5rem;
      border: 0;
      width: 100%;
      line-height: 2.5rem;
      text-align: center;
      box-sizing: border-box;
      border-radius: 8px;
      background: #db2020;
    }
  }
}
</style>
